.customcolor {
    color: '#616161' !important;
}

.fill {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

.fill img {
    min-width: 100%;
    min-height: 100%;
}
